//=================================================
// Collapse
//=================================================

.accordion {
	.btn-link {
		padding: 0;
		border: 0;
	}
}
