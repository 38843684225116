//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 5.263rem;
        }
    }
}

/*=============================
// Header
=============================*/
.site-header, .modal-header {
    .logo {
        img {
            width: 4.579rem;
        }
    }
}

